import $ from "jquery";
import Service from "@/services/services.service.js"
import exportBtns from "./util/export-buttons"
import { serverUrl } from "@/global/variables";

// Global vars
let datatable = null;
let rows = [];   // Global rows for #example
let fromId = null;

// Setup Table
export const setupTable = () => {
    datatable = $("#vue-datatable").DataTable({
        processing: true,
        serverSide: true,
        paging: false,
        ajax: {
            url: serverUrl(`/api/services/all`),
            beforeSend: function (request) {
                request.setRequestHeader(
                    "Authorization",
                    `Bearer ${localStorage.getItem("token")}`
                );
            },
        },
        buttons: [...exportBtns(), "columnsToggle"],
        dom: "Bfrtip",
		pageLength: 50,
        columns: [
            { data: "id" },
            { data: "name" },
            { data: "web_title" },
            { data: "price" },
            { data: "process_time" },
            { data: "php_id" },
            { data: "source.name" },
            { data: "orders" },
            { name: "actions", data: "actions" },
        ],
        // Add HTML5 draggable class to each row
        createdRow: function (row, data, dataIndex, cells) {
            $(row).attr('draggable', 'true');
        },
        drawCallback: function () {
            // Add HTML5 draggable event listeners to each row
            rows = document.querySelectorAll('#vue-datatable tbody tr');
            [].forEach.call(rows, function (row) {
                row.addEventListener('dragstart', handleDragStart, false);
                row.addEventListener('dragenter', handleDragEnter, false)
                row.addEventListener('dragover', handleDragOver, false);
                row.addEventListener('dragleave', handleDragLeave, false);
                row.addEventListener('drop', handleDrop, false);
                row.addEventListener('dragend', handleDragEnd, false);
            });
        },
        columnDefs: [
            {
                targets: 0,
                orderable: false,
            },
            {
                targets: 1,
                orderable: false,
            },
            {
                targets: 2,
                orderable: false,
            },
            {
                targets: 3,
                orderable: false,
            },
            {
                targets: 4,
                orderable: false,
            },
            {
                targets: 5,
                orderable: false,
            },
            {
                targets: 6,
                orderable: false,
            },
            {
                targets: 7,
                orderable: false,
            },
            {
                targets: 8,
                orderable: false,
            },
            {
                render: function (data, type, row) {
                    const classesBadge = [
                        "badge",
                        data === 'ADMIN' ? `badge-light-success` : `badge-light-info`,
                        "badge-pill",
                    ].join(" ");
                    return `<span class='${classesBadge}'>` + data + "</span>";
                },
                targets: 5,
            },
            {
                render: function (data, type, row) {
                    const editBtn = `<button type="button" onClick="window.editService(${row.id})" class="btn btn-icon rounded-circle btn-flat-success editBtn"><svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></button>`
                    return editBtn;
                },
                targets: 8,
            },
        ],
    });
    return datatable
}

function handleDragStart(e) {
    // Set the source row opacity
    this.style.opacity = '0.4';
    // Keep track globally of the source row and source table id
    fromId = +this.firstChild.innerText
    // Allow moves
    e.dataTransfer.effectAllowed = 'move';
}

function handleDragOver(e) {
    if (e.preventDefault) e.preventDefault();
    // Allow moves
    e.dataTransfer.dropEffect = 'move';
    return false;
}

function handleDragEnter(e) {
    if (+e.target.parentNode.firstChild.innerText !== fromId) this.classList.add('over');
}

function handleDragLeave(e) {
    // Remove the drop zone when leaving element
    this.classList.remove('over');
}

async function handleDrop(e) {
    if (e.stopPropagation) {
        e.stopPropagation(); // stops the browser from redirecting.
    }
    const toId = +e.target.parentNode.firstChild.innerText
    if (toId !== fromId) {
        console.log('from', fromId, 'to', toId);
        await Service.position(fromId, toId);
        // refresh data table
        datatable.ajax.reload()
    }
}

function handleDragEnd(e) {
    // Reset the opacity of the source row
    this.style.opacity = '1.0';
    // reset everything
    [].forEach.call(rows, function (row) {
        row.classList.remove('over');
        row.style.opacity = '1.0';
    });
}
