import api from "@axios";

// FOR SOURCES
class Source {
    // Get all sources - admin
    static async fetch() {
        const { data } = await api.get(`/sources`);
        return data
    }
}

export default Source;