<template>
  <section id="listUsers">
    <!-- Add Service -->
    <AddService @added="refresh" />
    <!-- Table View -->
    <VueDatatable ref="myTable" :setupTable="setupTable" :hideFooter="true">
      <template v-slot:thead>
        <tr>
          <th class="id-col" style="width: 59px">
            <span>ID</span>
          </th>
          <th>Name</th>
          <th>Web Title</th>
          <th>Price</th>
          <th>Process Time</th>
          <th>PHP id</th>
          <th>Source Name</th>
          <th>Orders</th>
          <th>Actions</th>
        </tr>
      </template>
    </VueDatatable>
    <!-- Edit Service -->
    <EditService @updated="refresh" />
  </section>
</template>

<script>
import { BButton, BNav, BNavItem } from "bootstrap-vue";
import VueDatatable from "@/components/util/vue-datatable/Index.vue";
import { setupTable } from "@/datatable/services.datatable.js";
import EditService from "./EditService";
import AddService from "./AddService";

export default {
  data: () => ({
    dataTable: null,
    setupTable,
  }),
  watch: {
    category(val) {
      this.myTable.setup();
    },
  },
  async mounted() {
    this.dataTable = this.$refs.myTable;
  },
  methods: {
    refresh() {
      this.myTable.refresh();
    },
  },
  computed: {
    myTable() {
      return this.dataTable || {};
    },
  },
  components: {
    BButton,
    VueDatatable,
    EditService,
    AddService,
    BNav,
    BNavItem,
  },
};
</script>

<style>
</style>