<template>
  <div id="addService">
    <div class="text-right mb-2">
      <b-button @click="status = true" variant="success">Add Service</b-button>
    </div>
    <!-- Add Modal -->
    <b-modal
      v-model="status"
      id="modal-center"
      centered
      title="Add Service"
      ok-title="Add"
      @ok="add"
    >
      <!-- Loading -->
      <div v-if="loading" class="text-center">
        <b-spinner style="width: 3rem; height: 3rem" />
      </div>
      <!-- Service Content -->
      <div v-else>
        <!-- name -->
        <b-form-group label="Name" label-for="name">
          <b-form-input v-model="form.name" placeholder="Enter Name" />
        </b-form-group>
        <!-- web title -->
        <b-form-group label="Web Title" label-for="webtitle">
          <b-form-input
            v-model="form.web_title"
            placeholder="Enter Web Title"
          />
        </b-form-group>
        <!-- description -->
        <b-form-group label="Description" label-for="description">
          <b-form-textarea
            v-model="form.description"
            placeholder="Description"
            rows="3"
          />
        </b-form-group>
        <!-- sample -->
        <b-form-group label="Sample" label-for="sample">
          <b-form-textarea
            v-model="form.sample"
            placeholder="Sample"
            rows="3"
          />
        </b-form-group>
        <!-- PHP id -->
        <b-form-group label="PHP id" label-for="php_id">
          <cleave
            v-model.number="form.php_id"
            class="form-control"
            :options="isNumber"
            placeholder="enter number"
          />
        </b-form-group>
        <!-- category -->
        <b-input-group class="input-group-merge mb-1">
          <v-select
            class="select-list"
            v-model="form.category"
            :options="['UNLOCK', 'CHECK']"
            placeholder="Select category"
          />
        </b-input-group>
        <!-- sources -->
        <b-input-group class="input-group-merge mb-1">
          <v-select
            class="select-list"
            @input="form.source_id = $event.id"
            label="name"
            :options="sources"
            placeholder="Select source"
          />
        </b-input-group>
        <!-- Process time -->
        <b-form-group label="Process time" label-for="process_time">
          <b-form-input
            v-model="form.process_time"
            placeholder="Enter Process Time"
          />
        </b-form-group>
        <!-- curl max -->
        <b-form-group label="Curl max" label-for="curl_max">
          <cleave
            v-model.number="form.curl_max"
            class="form-control"
            :options="isNumber"
            placeholder="enter number"
          />
        </b-form-group>
        <!-- price groups -->
        <div class="group-pricing">
          <b-form-group label="Normal Price" label-for="price">
            <cleave
              v-model.number="form.price"
              class="form-control"
              :options="isNumber"
              placeholder="1,000"
            />
          </b-form-group>
          <b-form-group label="Reseller Price" label-for="price">
            <cleave
              v-model.number="form.reseller_price"
              class="form-control"
              :options="isNumber"
              placeholder="1,000"
            />
          </b-form-group>
        </div>
        <!-- IS Active -->
        <b-form-checkbox
          @input="(v) => (form.is_active = v)"
          :checked="!!form.is_active"
          class="custom-control-primary"
          name="check-button"
          switch
          inline
        >
          Is Active
        </b-form-checkbox>
        <!-- IS Object -->
        <b-form-checkbox
          @input="(v) => (form.is_object = v)"
          :checked="!!form.is_object"
          class="custom-control-primary"
          name="check-button"
          switch
          inline
        >
          Is Object
        </b-form-checkbox>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import Service from "@/services/services.service.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Source from "@/services/sources.service.js";

export default {
  data: () => ({
    loading: false,
    status: false,
    isNumber: {
      numeral: true,
      numeralThousandsGroupStyle: "thousand",
    },
    form: {
      name: null,
      web_title: null,
      price: null,
      reseller_price: null,
      description: null,
      sample: null,
      process_time: null,
      php_id: null,
      source_id: null,
      curl_max: null,
      category: null,
      is_object: false,
    },
    sources: [],
  }),
  mounted() {
    Source.fetch().then((data) => (this.sources = data));
  },
  methods: {
    async add() {
      this.loading = true;
      try {
        await Service.add(this.form);
        this.$emit("added");
      } catch (e) {
        console.log(e);
        const msg =
          e.response.status == 422
            ? Object.values(e.response.data.errors)[0][0]
            : "Something went wrong, please try again";
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Faild to add",
            icon: "XIcon",
            text: msg,
            variant: "danger",
          },
        });
      }
      this.loading = false;
    },
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    vSelect,
    Cleave,
    // eslint-disable-next-line vue/no-unused-component
    ToastificationContent,
    BFormTextarea,
    BFormCheckbox,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.select-list {
  width: 100%;
}
.group-pricing {
  display: flex;
  align-items: center;
  > * {
    &:first-child {
      margin-right: 10px;
    }
    flex: 1;
  }
}
</style>